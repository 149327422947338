import React from 'react';
import config from './config';
import ActionButton from './ActionButton';
import './Donate.css';

const goFundMeUrl = 'https://www.gofundme.com/f/bubbasbeds';
const donateBedUrl = 'https://bubbasbeds.bigcartel.com/product/donate-a-bed';

export default () => (
  <div className="donate-section-container">
    <div className="donate-intro">
      <h1>We believe every shelter animal deserves a warm bed.</h1>
      <h2>You can help us make that dream a reality.</h2>
    </div>
    <div className="donate-options">
      <div className="donate-option-container">
        <div className="donate-option">
          <iframe
            title='GoFundMe'
            className='gfm-media-widget'
            image='1'
            coinfo='0'
            width='100%'
            height='100%'
            frameBorder='0'
            id='bubbasbeds'
          />
        </div>
        <a href={goFundMeUrl} target="_blank" rel="noopener noreferrer">
          <ActionButton
            text="GIVE TO GOFUNDME"
            buttonClass="button-x-small button-reverse-orange max-width-300"
          />
        </a>
      </div>
      <div className="donate-option-container">
        <div className="donate-option">
          <a href={donateBedUrl} target="_blank" rel="noopener noreferrer">
            <img src={`${config.cloudfrontUrl}donate-bed.jpg`} alt="Donate a Bed" />
          </a>
        </div>
        <a href={donateBedUrl} target="_blank" rel="noopener noreferrer">
          <ActionButton
            text="DONATE A BED"
            buttonClass="button-x-small button-reverse-orange max-width-300"
          />
        </a>
      </div>
    </div>
  </div>
);
